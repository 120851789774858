import Repo from './_repo.js'

export default class extends Repo {
  static create(cardId, memberId) {
    const data = { value: memberId }
    const currentTimestamp = new Date().getTime()
    return $tpu.r.data.restFetch(`/cards/${cardId}/idMembers`, 'POST', data).then((result) => {
      if (result) { // Success
        $tpu.r.cards.$mergeMembers(this._members(result), cardId, currentTimestamp)
      }
      return null
    })
  }

  static destroy(cardId, memberId) {
    const currentTimestamp = new Date().getTime()
    return $tpu.r.data.restFetch(`/cards/${cardId}/idMembers/${memberId}`, 'DELETE').then((result) => {
      if (result) { // Success
        $tpu.r.cards.$mergeMembers(this._members(result), cardId, currentTimestamp)
      }
      return null
    })
  }

  static _members(items) {
    const boardMembers = $tpu.urls.boardMembers().reduce((acc, member) => {
      acc[member.id] = member
      return acc
    }, {})
    return items.map(item => boardMembers[item.id]).filter(n => n)
  }
}
